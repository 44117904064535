import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import pammbanner_image from "../../images/siteImages/companyProfile/pamm.png"
import manageorders from "../../images/siteImages/companyProfile/orders.svg";
import clickentry from "../../images/siteImages/companyProfile/click.svg";
import manageaccount from "../../images/siteImages/companyProfile/multiaccount.svg";
import forexrate from "../../images/siteImages/companyProfile/forex-rate.svg";
import reports from "../../images/siteImages/companyProfile/reports.svg";
import windows from "../../images/siteImages/companyProfile/windows.svg";
import lotallocation from "../../images/siteImages/companyProfile/lot-allocation.svg";
import percentallocation from "../../images/siteImages/companyProfile/percent-allocation.svg";
import balanceallocation from "../../images/siteImages/companyProfile/balance-allocation.svg";
import equityallocation from "../../images/siteImages/companyProfile/equity-allocation.svg";
import equitypercentallocation from "../../images/siteImages/companyProfile/equity-percent-allocation.svg";
import allocationequalrisk from "../../images/siteImages/companyProfile/allocation-equal-risk.svg";
import percentallocationpl from "../../images/siteImages/companyProfile/percent-allocation-pl.svg";
import proportionalallocationpl from "../../images/siteImages/companyProfile/proportional-allocation-pl.svg";
import mt4desktop from "../../images/siteImages/mt4Desktop/MT4_DESKTOP_DOWNLOAD.png";
import PaymentSlider from "./PaymentSlider";


const PAMM = () => {
  const language = useSelector((state) => state.language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={pammbanner_image} />
      <section className="sectionbg p-top-100 p-bottom-110 faqs">
        <Container className="">
          <Row>
            <Col lg={7}>
              <h2 className="my-3 text-bold-md text-black">Trade and manage multiple
                accounts with our PAMM platform</h2>
              <p className="text-gray my-3">Manage several forex accounts with ease, using the Rox Capitals Personal Multi Account Manager (PAMM) platform. Benefit from one of the most flexible and advanced platforms currently existing within the industry, suitable for both professional traders and money managers. The PAMM platform allows money managers to trade and manage multiple MT4 accounts at the same time. It offers clients an intuitive money management solution, featuring advanced tools to enable optimum market execution.</p>
            </Col>
            <Col lg={5} className="d-flex align-items-center">
              <img alt="ddd" src={mt4desktop} />
            </Col>
            <Col lg={12}>
              <h2 className="my-3 text-bold-md text-black">PAMM benefits and trading features</h2>
              <p className="text-gray my-3">The Rox Capitals PAMM software makes trading easier to manage, taking the stress out of handling multiple accounts. It allows the trader to focus on trading through the MetaTrader 5 Master Account, as PAMM takes care of all the other processes involved.</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={manageorders} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    Manage orders, positions, and equity online
                  </h4>
                </div>
              </div>
            </Col>
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={clickentry} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    One-click order entry
                  </h4>
                </div>
              </div>
            </Col>
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={manageaccount} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    Manage unlimited accounts
                  </h4>
                </div>
              </div>
            </Col>
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={forexrate} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    Monitoring of financial instruments’ prices and forex rates
                  </h4>
                </div>
              </div>
            </Col>
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={reports} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    Activity reports
                  </h4>
                </div>
              </div>
            </Col>
            <Col className="mb-5"
              lg={4}
              md={6}>
              <div className="pmam-card mb-4 h-100">
                <div className="d-flex flex-column mb-2 align-items-center">
                  <div className="box-icon mb-4">
                    <img alt="ddd" src={windows} className="icon-width" style={{ width: "60px" }} />
                  </div>
                  <h4 className="text-white text-center text-bold-md mb-0 mx-3">
                    Supported OS: Microsoft Windows 98 or higher
                  </h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={12}>
              <h2 className="my-3 text-bold-md text-black mb-5">PAMM allocation methods</h2>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={lotallocation} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Lot allocation
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Used to divide volume of the master trade to the sub accounts, according to the set parameter of the sub accounts. It allows trading with variable lot sizes on the master account. The allocation to the sub accounts will be proportional to the set lot sizes provided for each of them.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={percentallocation} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Percent allocation
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Used to divide volume of the master trade to the sub accounts according to the set percent (%) parameter of the sub accounts. The allocation to the sub accounts will be calculated as a percentage of the master trade volume.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={balanceallocation} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Proportional by Balance allocation
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Used to calculate the proportion of the master trade volume according to the balances of the sub accounts. This method does not require additional settings. The fraction of the master trade volume is calculated automatically.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={equityallocation} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Proportional by Equity allocation
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  This method is similar to the previous allocation in the way that proportion is calculated automatically. The difference is that equity is used instead of balance. This method does not require additional settings. The fraction of the master trade volume is calculated automatically.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={equitypercentallocation} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Equity percent allocation
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Used when the user requires allocating to sub accounts on a percentage basis of each individual sub account. The idea is to make it possible to define the risk for each individual sub account. This method is using percent (%) parameter of the sub accounts to define the percentage of the sub account equity which will be traded in every trade.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={allocationequalrisk} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Allocation by Equal Risk
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Is introduced to address the problem of margin control on the sub accounts. Sometimes the sub accounts are stopped because of the low margin level. No other allocation method is taking into consideration the margin level of the individual sub accounts. But with this method it is possible to set the minimal % level for each of the sub accounts.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={percentallocationpl} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Percent allocation by p/l
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  This method is made without opening positions on the sub accounts, but when the master position is closed the allocation to the sub accounts is made with a deposit/withdrawal transaction. It is used when the user requires allocation to the sub accounts on a percentage basis according to the set percent (%) parameter similar to the percent allocation method.
                </p>
              </div>
            </Col>
            <Col className="mb-5"
              lg={6}
              md={6}>
              <div className="pmam-allocation mb-4 h-100">
                <div className="d-flex mb-2 align-items-center">
                  <div className="box-icon me-2">
                    <div className="">
                      <img alt="ddd" src={proportionalallocationpl} className="icon-width" style={{ width: "45px" }} />
                    </div>
                  </div>
                  <h6 className="text-gray text-bold-md mb-0">
                    Proportional allocation by p/l
                  </h6>
                </div>
                <p className="text-gray line-height-lg">
                  Similar to the previous method in the way that no trades are open on the sub accounts. The deposit/withdrawal is made to the sub accounts when the master trade is closed. This method is used when the user requires allocating to sub accounts proportionally to their balances.
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </section>

      <section className="sectionbg py-4 mt-0">
<PaymentSlider/>
      </section>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(PAMM);
